import { useAppDispatch } from "../../../app";
import { useCallback } from "react";
import { resetAnnotationReducer } from "../../../annotation/store/annotationSlice";
import { resetDocumentReducer } from "../../../document/store/documentSlice";
import { resetConfigMapReducer } from "../../../configMap/store/configMapSlice";
import { resetAppReducer } from "../../../app/store/appSlice";
import { resetDocumentReducerForOverview } from "../../../documentSet/store/documentSetSlice";
import { resetAnalyticsReducer } from "../../../analytics/store/analyticsSlice";
import { resetMetadataReducer } from "../../../metadata/store/metadataSlice";
import { resetAndCloseDrawer } from "../../../annotationSideControls/store/AnnotationSideControlsSlice";
import { resetAnnotatorReducer } from "../../../annotator/store/AnnotatorSlice";

const useReduxReset = () => {
  const dispatch = useAppDispatch();

  const detailReset = useCallback(() => {
    dispatch(resetAppReducer());
    dispatch(resetAnnotationReducer());
    dispatch(resetConfigMapReducer());
    dispatch(resetDocumentReducer());
    dispatch(resetDocumentReducerForOverview());
    dispatch(resetAnalyticsReducer());
    dispatch(resetAndCloseDrawer());
    dispatch(resetMetadataReducer());
    dispatch(resetAnnotatorReducer());
  }, [dispatch]);

  const overviewReset = useCallback(() => {
    dispatch(resetAppReducer());
    dispatch(resetAnnotationReducer());
    dispatch(resetConfigMapReducer());
    dispatch(resetMetadataReducer());
  }, [dispatch]);

  return { detailReset, overviewReset };
};

export default useReduxReset;
