import { useAppSelector } from "../../app";
import { useCallback } from "react";
import { ICategorization } from "../../configMap/interfaces/category";
import useAnnotationNormalization from "../../configMap/hooks/useAnnotationNormalization";
import { mapToEntityAnnotationDto } from "../../annotation/utils/annotationMappers";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";

const useEntityAnnotations = () => {
  const entityAnnotations = useAppSelector(selectAnnotationReducer);

  const { normalizeEntityAnnotationsInBulk } = useAnnotationNormalization();

  return useCallback(
    async (
      categorization: ICategorization,
      categoryId: string,
      index: number | undefined,
      hasGroupBlock: boolean
    ) => {
      const category = categorization?.categories.find(
        (c) => c.id === categoryId
      );
      if (!categorization || !category || !categorization.entityTypes?.length) {
        return;
      }

      const entityTypes = categorization.entityTypes;
      const filteredEntityAnnotations = entityAnnotations.filter((ea) =>
        entityTypes.some(
          (et) =>
            ea.entity.id === et.id &&
            (!hasGroupBlock || !index || ea.index === index)
        )
      );

      const entityAnnotationsToNormalize = filteredEntityAnnotations
        .filter((ea) => ea.entity?.entityNormalizations?.length)
        .map((ea) => {
          const selectedValue = category.value;

          return mapToEntityAnnotationDto(
            ea.id,
            ea.values.join(" "),
            ea.index ?? 1,
            ea.entity.entityNormalizations!,
            ea.entity.id,
            ea.multipleGroupBlocks,
            selectedValue
          );
        });
      normalizeEntityAnnotationsInBulk(entityAnnotationsToNormalize).catch(
        () => undefined
      );
    },
    [entityAnnotations, normalizeEntityAnnotationsInBulk]
  );
};

export default useEntityAnnotations;
