import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import dayjs from "dayjs";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { ActionType, HistoryItem, HistoryItemType } from "../interfaces/drawer";
import { NormalizationErrorType } from "../../annotation/interfaces/annotation";

export const useAnnotationHistoryContent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation("annotationSideControls", {
    keyPrefix: "sideDrawer.annotationHistory",
  });

  const getDate = useCallback((item: HistoryItem) => {
    if (!item.createdDate) {
      return;
    }

    return dayjs(item.createdDate).format("DD/MM/YYYY HH:mm");
  }, []);

  const getTitle = useCallback((item: HistoryItem) => {
    let actionString;
    if (item.actionType === ActionType.ADD) {
      actionString = "added";
    } else if (item.actionType === ActionType.MODIFIED) {
      actionString = "modified";
    } else if (item.actionType === ActionType.DELETE) {
      actionString = "deleted";
    }
    let itemString;
    if (item.type === HistoryItemType.ENTITY) {
      itemString = "Entity";
    } else if (item.type === HistoryItemType.CATEGORIZATION) {
      itemString = "Enricher";
    }

    return `${itemString} ${actionString}`;
  }, []);

  const getIsError = useCallback((item: HistoryItem) => {
    return (
      item.errorType === NormalizationErrorType.INVALID_VALUE ||
      item.errorType === NormalizationErrorType.ERROR_WHILE_NORMALIZING
    );
  }, []);

  const getEntityName = useCallback(
    (item: HistoryItem) => {
      let entityString = "";
      if (item.itemTranslations) {
        entityString = getInLanguageOrDefault(
          JSON.parse(item.itemTranslations as any),
          language
        );
      }

      if (item.groupBlockTranslations) {
        let groupBlockString = getInLanguageOrDefault(
          JSON.parse(item.groupBlockTranslations as any),
          language
        );
        if (item.multipleGroupBlocks) {
          groupBlockString += ` #${item.index}`;
        }

        return `${entityString} [${groupBlockString}]`;
      }

      return entityString;
    },
    [language]
  );

  return {
    getDate,
    getTitle,
    getEntityName,
    getIsError,
    t,
  };
};
