import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Validation,
  ValidationTypes,
} from "../../../common/validation/constants";
import { useValidation } from "../../index";
import ValidationIconMapper from "../../../common/validation/ValidationIconMapper";
import { NormalizationErrorType } from "../../../annotation/interfaces/annotation";

type Props = {
  value: string;
  normalizedValue: string | undefined;
  validationId: string;
  validations: Array<Validation>;
  options?: Array<string>;
  errorMessage?: string;
  errorType?: NormalizationErrorType;
};

const EntityValidation: FC<Props> = ({
  value,
  normalizedValue,
  validationId,
  validations,
  options,
  errorMessage,
  errorType,
}) => {
  const { t } = useTranslation("entityValidations");

  const validation = useValidation(
    normalizedValue ?? value,
    validationId,
    validations
  );

  const isErrorWhileNormalizing = useMemo(() => {
    return errorType === NormalizationErrorType.ERROR_WHILE_NORMALIZING;
  }, [errorType]);

  const isValidOption = useMemo(() => {
    if (!options?.length || !normalizedValue) {
      return true;
    }

    return options.includes(normalizedValue.toLowerCase());
  }, [normalizedValue, options]);

  const isInvalidValue = useMemo(() => {
    return errorType === NormalizationErrorType.INVALID_VALUE;
  }, [errorType]);

  const entityValidationClassNames = classNames({
    "entity-validation__container": true,
    "error-status":
      validation.type === ValidationTypes.ERROR ||
      !isValidOption ||
      errorMessage,
    "warning-status": validation.type === ValidationTypes.WARNING,
    "info-status": validation.type === ValidationTypes.INFO,
  });

  if (options?.length && !isValidOption) {
    return (
      <div className={entityValidationClassNames}>
        <ValidationIconMapper icon={validation.icon} />
        <span className="entity-validation__message">
          {isInvalidValue
            ? t("common.errors.errorWhileMapping").replace(
                "$OPTIONS",
                options.join(", ")
              )
            : t("common.errors.errorWhileMappingManual").replace(
                "$OPTIONS",
                options.join(", ")
              )}
        </span>
      </div>
    );
  }

  if (isInvalidValue && errorMessage) {
    return (
      <div className={entityValidationClassNames}>
        <ValidationIconMapper icon={validation.icon} />
        <span className="entity-validation__message">{errorMessage}</span>
      </div>
    );
  }

  if (!validation.show) {
    return null;
  }

  if (isErrorWhileNormalizing) {
    return (
      <div className="entity-validation__container error-status">
        <ValidationIconMapper icon="ExclamationCircle" />
        <span className="entity-validation__message">
          {t(`common.errors.errorWhileNormalizing`)}
        </span>
      </div>
    );
  }

  return (
    <div className={entityValidationClassNames}>
      <ValidationIconMapper icon={validation.icon} />
      <span className="entity-validation__message">
        {t(`${validation.message}`)}
      </span>
    </div>
  );
};

export default EntityValidation;
