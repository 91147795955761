import { useCallback } from "react";
import { notification } from "antd";
import { User } from "../../user";
import { Status, todoStatuses } from "../../common/status/status";
import { DocumentSetData } from "../interfaces/documentSet";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchDocumentSetsTrigger,
  removeDocumentSet,
  setActiveDocumentSet,
} from "../store/documentSetSlice";
import { useTranslation } from "react-i18next";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";
import { mapAnnotationsToAnnotatorFormat } from "../../annotation";
import {
  addAnnotationsForDocument,
  setTableAnnotations,
} from "../../annotation/store/annotationSlice";
import { setDocumentCategoryAnnotationsBatch } from "../../metadata/store/metadataSlice";
import {
  configMapIsLoading,
  setConfigMap,
} from "../../configMap/store/configMapSlice";
import { STATUSES_TO_NORMALIZE_ANNOTATIONS } from "../../document/constants";
import { mapToEntityAnnotationDto } from "../../annotation/utils/annotationMappers";
import { useMultipleGroupBlocks } from "../../configMap";
import useAnnotationNormalization from "../../configMap/hooks/useAnnotationNormalization";
import { shouldFetchDocumentSetData } from "../utils/helperFunctions";
import {
  useLazyGetDocumentSetDataQuery,
  useLazyUpdateDocumentSetQuery,
} from "../store/documentSetApi";
import { parseReduxQueryError } from "../../common/reduxQuery/helpers";
import { changeIsTokenRefreshing } from "../../user/store/userSlice";

const useActiveDocumentSet = () => {
  const { navigateWithState } = useNavigateWithState();
  const { t } = useTranslation("errors");

  const dispatch = useAppDispatch();
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );
  const user = useAppSelector((state) => state.userReducer.user);

  const { setInitialMultipleGroupBlocksCount } = useMultipleGroupBlocks();
  const { normalizeEntityAnnotationsInBulk } = useAnnotationNormalization();

  const [getDocumentSetData] = useLazyGetDocumentSetDataQuery();

  const [updateDocumentSet] = useLazyUpdateDocumentSetQuery();

  const updateActiveDocumentSet = useCallback(
    (documentSet?: DocumentSetData) => {
      dispatch(setActiveDocumentSet(documentSet));
    },
    [dispatch]
  );

  const fetchDocumentActiveDocumentSet = useCallback(
    async (documentSetId: string, signal: AbortSignal, routeBack = false) => {
      if (
        activeDocumentSet?.id === documentSetId &&
        !shouldFetchDocumentSetData(activeDocumentSet)
      ) {
        return;
      }

      dispatch(configMapIsLoading());

      try {
        const data = await getDocumentSetData(documentSetId).unwrap();

        if (data) {
          updateActiveDocumentSet(data);

          if (!data.documents) {
            return;
          }

          const { documentType, id } = data.documents[0];
          const configMap = documentType?.configMap;
          const categorizations = configMap?.categorizationConfigMaps.map(
            (c) => c.categorization
          );

          const flatAnnotations = data.documents.flatMap(
            (d) => d.entityAnnotations?.filter(Boolean) || []
          );
          const flatCategoryAnnotations = data.documents
            .flatMap(
              (d) => d.documentCategoryAnnotations?.filter(Boolean) || []
            )
            .map((dca) => ({
              ...dca,
              groupBlockId:
                dca.groupBlockId !== null ? dca.groupBlockId : undefined,
              index: dca.index,
            }));
          const flatTableAnnotations = data.documents.flatMap(
            (d) => d.tableAnnotations?.filter(Boolean) || []
          );

          const mappedAnnotations = mapAnnotationsToAnnotatorFormat(
            flatAnnotations,
            configMap
          );

          dispatch(setConfigMap(configMap!));
          dispatch(changeIsTokenRefreshing(false));
          dispatch(setTableAnnotations(flatTableAnnotations));
          dispatch(
            setDocumentCategoryAnnotationsBatch(flatCategoryAnnotations)
          );
          dispatch(
            addAnnotationsForDocument({
              documentId: id,
              annotations: mappedAnnotations,
            })
          );
          setInitialMultipleGroupBlocksCount(
            configMap!,
            mappedAnnotations,
            flatCategoryAnnotations
          );

          if (STATUSES_TO_NORMALIZE_ANNOTATIONS.includes(data.status)) {
            const annotationsToNormalize = mappedAnnotations
              .filter(
                (ea) =>
                  ea.isOutput &&
                  !ea.entityAnnotationNormalization &&
                  ea.entity.entityNormalizations?.length
              )
              .map((ea) => {
                return mapToEntityAnnotationDto(
                  ea.id,
                  ea.values.join(" "),
                  ea.index ?? 1,
                  ea.entity.entityNormalizations!,
                  ea.entity.id,
                  ea.multipleGroupBlocks
                );
              });

            normalizeEntityAnnotationsInBulk(
              annotationsToNormalize,
              true,
              categorizations,
              flatCategoryAnnotations
            );
          }
        }
      } catch (error: any) {
        if (signal.aborted) {
          return;
        }

        if (error.status === 400 && routeBack) {
          notification.error({
            message: t("documentSetBadRequest"),
          });
          navigateWithState("/");
          return;
        } else if (error.status === 403 && routeBack) {
          notification.error({
            message: t("documentSetUnauthorized"),
          });
          navigateWithState("/");
          return;
        } else if (error.status === 404 && routeBack) {
          notification.error({
            message: t("documentSetNotFound"),
          });
          navigateWithState("/");
          return;
        } else if (routeBack) {
          notification.error({
            message: t("documentSetOtherError"),
          });
          navigateWithState("/");
          return;
        } else {
          notification.error({
            message: t("documentSetOtherError"),
          });
        }

        console.error(parseReduxQueryError(error));
      }
    },
    [
      activeDocumentSet,
      dispatch,
      updateActiveDocumentSet,
      setInitialMultipleGroupBlocksCount,
      normalizeEntityAnnotationsInBulk,
      t,
      navigateWithState,
      getDocumentSetData,
    ]
  );

  const updateAssigneeAndStatus = useCallback(
    async (
      documentSet: DocumentSetData,
      newStatus?: Status,
      assignee?: User,
      shouldUpdateActiveDocumentSet = true
    ): Promise<DocumentSetData> => {
      const newAssigneeDocumentSet = { ...documentSet };

      if (newStatus) {
        newAssigneeDocumentSet.status = newStatus;
      }
      newAssigneeDocumentSet.assignee = assignee;

      await updateDocumentSet({ documentSet, newStatus, assignee }).unwrap();
      if (newAssigneeDocumentSet && shouldUpdateActiveDocumentSet) {
        updateActiveDocumentSet(newAssigneeDocumentSet);
      }

      return newAssigneeDocumentSet;
    },
    [updateActiveDocumentSet, updateDocumentSet]
  );

  const updateAssigneeAndStatusWithExceptionHandling = useCallback(
    async (
      documentSet: DocumentSetData,
      newStatus?: Status,
      assignee?: User,
      shouldUpdateActiveDocumentSet = true
    ): Promise<DocumentSetData> => {
      try {
        return updateAssigneeAndStatus(
          documentSet,
          newStatus,
          assignee,
          shouldUpdateActiveDocumentSet
        );
      } catch (e) {
        return documentSet;
      }
    },
    [updateAssigneeAndStatus]
  );

  const assignUserToActiveDocument = useCallback(async () => {
    if (!activeDocumentSet?.assignee) {
      await updateAssigneeAndStatusWithExceptionHandling(
        activeDocumentSet!,
        undefined,
        user!
      );
    } else if (
      activeDocumentSet?.assignee?.username !== user?.id &&
      todoStatuses.find((status) => status === activeDocumentSet?.status) !==
        undefined
    ) {
      navigateWithState("/");
    }
  }, [
    updateAssigneeAndStatusWithExceptionHandling,
    user,
    activeDocumentSet,
    navigateWithState,
  ]);

  const handleRemoveDocumentSet = useCallback(
    async (documentSet: DocumentSetData) => {
      await updateAssigneeAndStatusWithExceptionHandling(
        documentSet,
        Status.Remove,
        user!,
        false
      );
      dispatch(removeDocumentSet(documentSet));
      dispatch(fetchDocumentSetsTrigger());
    },
    [dispatch, updateAssigneeAndStatusWithExceptionHandling, user]
  );

  const handleRestoreDocumentSet = useCallback(
    async (documentSet: DocumentSetData) => {
      const status = documentSet.metadata?.preRemoveStatus || Status.ToDo;

      await updateAssigneeAndStatusWithExceptionHandling(
        documentSet,
        status,
        undefined,
        false
      );
      dispatch(removeDocumentSet(documentSet));
      dispatch(fetchDocumentSetsTrigger());
    },
    [dispatch, updateAssigneeAndStatusWithExceptionHandling]
  );

  return {
    activeDocumentSet,
    updateActiveDocumentSet,
    assignUserToActiveDocument,
    updateAssigneeAndStatusWithExceptionHandling,
    updateAssigneeAndStatus,
    fetchDocumentActiveDocumentSet,
    handleRemoveDocumentSet,
    handleRestoreDocumentSet,
  };
};

export default useActiveDocumentSet;
