import { findIntersectingChildren, isCoordsEmpty } from "./selectionHelpers";
import { Entity } from "../interfaces/entity";
import { PDFMetaData } from "../interfaces/pdf";
import { AnnotationParams } from "../interfaces/annotation";
import { ITextVertex, Rectangle } from "../interfaces/textLayer";

const getImageAsBase64 = (
  targetCoords: Rectangle,
  context: CanvasRenderingContext2D
): string => {
  const { left, top, width, height } = targetCoords;
  const imageContentRaw = context.getImageData(left, top, width, height);
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  canvas.getContext("2d")?.putImageData(imageContentRaw, 0, 0);
  return canvas.toDataURL("image/jpeg", 1.0);
};

export const buildNerAnnotation = (
  pageNumber: number,
  entity: Entity,
  tokens: Array<ITextVertex>,
  text: string,
  targetCoords: Rectangle,
  multipleGroupBlocks: boolean
): AnnotationParams => {
  const intersects = findIntersectingChildren(tokens, targetCoords);
  const values = intersects.map((i) =>
    text.slice(i.textStartIndex, i.textEndIndex).replace(/\n$/, "")
  );

  return {
    page: pageNumber,
    pageTokenIndices: intersects.map((i) => i.dataI),
    values: values,
    entity: entity!,
    index: entity!.index,
    tempAnnotation: false,
    isByUser: true,
    isOutput: false,
    isLoading: false,
    multipleGroupBlocks,
  };
};

export const buildAreaAnnotation = (
  pageNumber: number,
  entity: Entity,
  targetCoords: Rectangle,
  pdfInformation: PDFMetaData,
  context: CanvasRenderingContext2D
): AnnotationParams | null => {
  if (isCoordsEmpty(targetCoords)) {
    return null;
  }

  return {
    page: pageNumber,
    areaAnnotation: {
      boundingBox: targetCoords,
      pdfInformation,
      base64Image: getImageAsBase64(targetCoords, context),
    },
    entity: entity!,
    tempAnnotation: false,
    isByUser: true,
    pageTokenIndices: [],
    values: [],
    isOutput: false,
    isLoading: false,
    multipleGroupBlocks: false,
  };
};
