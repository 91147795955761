import React, { FC, useMemo } from "react";
import { calculateTextWidth } from "../../../common/utilities/text";
import { Tooltip } from "antd";
import classNames from "classnames";
import { NormalizationErrorType } from "../../../annotation/interfaces/annotation";

const DOUBLE_COLUMNS_WIDTH = 150;
const SINGLE_COLUMN_WIDTH = 320;

type Props = {
  value: string;
  normalizedValue: string | undefined;
  showLabels: boolean;
  showNormalizedValue: boolean;
  requiresAttention: boolean;
  errorType?: NormalizationErrorType;
};

const EntityTextVisual: FC<Props> = ({
  value,
  normalizedValue,
  showLabels,
  showNormalizedValue,
  requiresAttention,
  errorType,
}) => {
  const textWidthValue = useMemo(
    () => calculateTextWidth(value || ""),
    [value]
  );
  const textWidthNormalizedValue = useMemo(
    () => calculateTextWidth(normalizedValue || ""),
    [normalizedValue]
  );
  const showValueTooltip = textWidthValue > DOUBLE_COLUMNS_WIDTH;
  const showNormalizedValueTooltip =
    textWidthNormalizedValue > DOUBLE_COLUMNS_WIDTH;

  const hasInvalidNormalizedValue = useMemo(() => {
    return (
      errorType === NormalizationErrorType.INVALID_VALUE ||
      errorType === NormalizationErrorType.ERROR_WHILE_NORMALIZING
    );
  }, [errorType]);

  const normalizedClassName = classNames({
    "entity-visual__element": true,
    invalid: hasInvalidNormalizedValue,
    "requires-attention": requiresAttention,
  });

  const labeledClassName = classNames({
    "entity-visual__element": true,
    "requires-attention": hasInvalidNormalizedValue ? false : requiresAttention,
  });

  const labeledWithoutNormalizedClassName = classNames({
    "entity-visual__element_labeled": true,
    "requires-attention": requiresAttention,
  });

  if (!showNormalizedValue) {
    const showTooltip = textWidthValue > SINGLE_COLUMN_WIDTH;
    const tooltip = showTooltip ? value : null;

    return (
      <Tooltip placement="top" title={tooltip}>
        <div className="entity-visual__container">
          <div className={labeledWithoutNormalizedClassName}>{value}</div>
        </div>
      </Tooltip>
    );
  }

  if (!value || !normalizedValue) {
    const tooltipValue = normalizedValue ?? value;
    const showTooltip = normalizedValue
      ? textWidthNormalizedValue > SINGLE_COLUMN_WIDTH
      : textWidthValue > SINGLE_COLUMN_WIDTH;
    const tooltip = showTooltip ? tooltipValue : null;

    return (
      <Tooltip placement="top" title={tooltip}>
        <div className="entity-visual__container">
          <div className={labeledWithoutNormalizedClassName}>
            {tooltipValue}
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="entity-visual__container">
      <div>
        {showLabels && <label className="entity-visual__title">Labeled</label>}
        <Tooltip placement="top" title={showValueTooltip ? value : null}>
          <div className={labeledClassName}>{value}</div>
        </Tooltip>
      </div>

      <div className="entity-visual__seperator">
        <i className="bi bi-chevron-right" />
      </div>

      <div>
        {showLabels && (
          <label className="entity-visual__title">Normalized</label>
        )}
        <Tooltip
          placement="top"
          title={showNormalizedValueTooltip ? normalizedValue : null}
        >
          <div className={normalizedClassName}>{normalizedValue}</div>
        </Tooltip>
      </div>
    </div>
  );
};

export default EntityTextVisual;
