import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AnnotationState,
  AnnotatorDocumentInfoPayload,
  LastAction,
  TableState,
} from "../interfaces/annotator";
import { ITable } from "../interfaces/ITable";
import { TextLayer } from "../interfaces/textLayer";

interface AnnotatorState {
  textLayer: Array<TextLayer>;
  annotationState: AnnotationState;
  tableState: TableState;
}

const initialState: AnnotatorState = {
  textLayer: [],
  annotationState: {
    createTable: false,
    currentPage: 1,
    pages: 0,
    error: false,
  },
  tableState: {
    tables: [],
    previousTableState: [],
    lastAction: "",
  },
};

export const annotatorSlice = createSlice({
  name: "annotator",
  initialState,
  reducers: {
    setTextLayer: (state, action: PayloadAction<Array<TextLayer>>) => {
      state.textLayer = action.payload;
    },
    setCreateTable: (state, action: PayloadAction<boolean>) => {
      state.annotationState.createTable = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.annotationState.currentPage = action.payload;
    },
    setAnnotatorDocumentInfo: (
      state,
      action: PayloadAction<AnnotatorDocumentInfoPayload>
    ) => {
      const { pages, error } = action.payload;
      state.annotationState.pages = pages;
      state.annotationState.error = error;
    },
    setAnnotatorTables: (state, action: PayloadAction<Array<ITable>>) => {
      state.tableState.tables = action.payload;
    },
    setPreviousTableState: (state, action: PayloadAction<Array<ITable>>) => {
      state.tableState.previousTableState = action.payload;
    },
    setTableLastAction: (state, action: PayloadAction<LastAction>) => {
      state.tableState.lastAction = action.payload;
    },
    resetAnnotatorState: (state) => {
      state.annotationState = { ...initialState.annotationState };
      state.tableState = { ...initialState.tableState };
      state.textLayer = { ...initialState.textLayer };
    },
    updateTableAnnotationsInState: (
      state,
      action: PayloadAction<Array<ITable>>
    ) => {
      state.tableState.tables = action.payload;
    },
    resetAnnotatorReducer: () => initialState,
  },
});

export const {
  setTextLayer,
  setCreateTable,
  setCurrentPage,
  setAnnotatorDocumentInfo,
  setAnnotatorTables,
  setPreviousTableState,
  setTableLastAction,
  resetAnnotatorState,
  updateTableAnnotationsInState,
  resetAnnotatorReducer,
} = annotatorSlice.actions;

export default annotatorSlice.reducer;
