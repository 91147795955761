import { useMemo } from "react";
import { EntityAnnotation } from "../../annotation";
import { NormalizationErrorType } from "../../annotation/interfaces/annotation";
import { useTranslation } from "react-i18next";

const useEntityAnnotation = (
  entityAnnotation: EntityAnnotation | undefined
) => {
  const { t } = useTranslation("annotations");

  const value = useMemo(() => {
    return entityAnnotation?.value ?? "";
  }, [entityAnnotation]);

  const normalizedValue = useMemo(() => {
    if (
      entityAnnotation?.entityAnnotationNormalization?.errorType ===
      NormalizationErrorType.INVALID_VALUE
    ) {
      return t("invalidValue");
    } else if (
      entityAnnotation?.entityAnnotationNormalization?.errorType ===
      NormalizationErrorType.ERROR_WHILE_NORMALIZING
    ) {
      return t("errorWhileNormalizing");
    }

    return entityAnnotation?.entityAnnotationNormalization?.normalizedValue;
  }, [entityAnnotation?.entityAnnotationNormalization, t]);

  const options = useMemo(() => {
    return entityAnnotation?.entityAnnotationNormalization?.options;
  }, [entityAnnotation?.entityAnnotationNormalization]);

  const hasNormalizedValue = useMemo(
    () => !!normalizedValue,
    [normalizedValue]
  );

  const errorType = useMemo(() => {
    return entityAnnotation?.entityAnnotationNormalization?.errorType;
  }, [entityAnnotation?.entityAnnotationNormalization?.errorType]);

  return {
    value,
    normalizedValue,
    options,
    hasNormalizedValue,
    errorType,
  };
};

export default useEntityAnnotation;
