import React, { FC, useMemo } from "react";
import EditEntityText from "./EditEntityText";
import EntityTextVisual from "../EntityTextVisual";
import { isRequiredValidation } from "../commonValidation";
import EntityValidation from "../EntityValidation";
import { Validation } from "../../../../common/validation/constants";
import useEntityAnnotation from "../../../hooks/useEntityAnnotation";
import { SingleAnnotationProps } from "../SingleAnnotation";

const TextEntity: FC<SingleAnnotationProps> = ({
  entityType,
  entityAnnotation,
  isEditing,
  isAnnotating,
  groupBlockIndex,
  documentId,
  requiresAttention,
  showLabels = true,
  isEditable = true,
  showNormalizedValue = true,
}) => {
  const { value, normalizedValue, options, errorType } =
    useEntityAnnotation(entityAnnotation);

  const mergedValidations = useMemo((): Array<Validation> => {
    const output: Array<Validation> = [];
    if (entityType.required) {
      output.push(isRequiredValidation);
    }

    return output;
  }, [entityType]);

  if (!entityAnnotation) {
    return null;
  }

  return (
    <>
      {documentId && isEditing && !isAnnotating && isEditable ? (
        <EditEntityText
          entityType={entityType}
          documentId={documentId}
          defaultValue={normalizedValue ?? value}
          annotationId={entityAnnotation.id!}
          groupBlockIndex={groupBlockIndex}
        />
      ) : (
        <EntityTextVisual
          value={value}
          normalizedValue={normalizedValue}
          showLabels={showLabels}
          showNormalizedValue={showNormalizedValue}
          requiresAttention={requiresAttention}
          errorType={errorType}
        />
      )}

      {isEditable && (
        <EntityValidation
          validationId={`${entityType.entityType.id}-${groupBlockIndex}`}
          value={value}
          normalizedValue={normalizedValue}
          validations={mergedValidations}
          options={options}
          errorMessage={
            entityAnnotation.entityAnnotationNormalization?.errorMessage
          }
          errorType={errorType}
        />
      )}
    </>
  );
};

export default TextEntity;
