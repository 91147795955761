import { useMemo } from "react";
import { ValidationResult } from "../../common/validation/constants";
import { useTranslation } from "react-i18next";
import { NormalizationErrorType } from "../../annotation/interfaces/annotation";

const useEntityValidationMessage = (
  validation: ValidationResult,
  isValidOption: boolean,
  options?: Array<string>,
  errorMessage?: string,
  errorType?: NormalizationErrorType
) => {
  const { t } = useTranslation("entityValidations");

  const isErrorWhileNormalizing = useMemo(() => {
    return errorType === NormalizationErrorType.ERROR_WHILE_NORMALIZING;
  }, [errorType]);

  const isInvalidValue = useMemo(() => {
    return errorType === NormalizationErrorType.INVALID_VALUE;
  }, [errorType]);

  return useMemo(() => {
    if (options?.length && !isValidOption) {
      return isInvalidValue
        ? t("common.errors.errorWhileMapping").replace(
            "$OPTIONS",
            options.join(", ")
          )
        : t("common.errors.errorWhileMappingManual").replace(
            "$OPTIONS",
            options.join(", ")
          );
    }

    if (errorMessage) {
      return errorMessage;
    }

    if (!validation.show) {
      return null;
    }

    if (isErrorWhileNormalizing) {
      return t(`common.errors.errorWhileNormalizing`);
    }

    return t(`${validation.message}`);
  }, [
    options,
    isValidOption,
    t,
    validation,
    isErrorWhileNormalizing,
    isInvalidValue,
    errorMessage,
  ]);
};

export default useEntityValidationMessage;
