import { Translation } from "../../configMap";
import { Dayjs } from "dayjs";
import { Input } from "../../documentSet";
import {
  EmailReply,
  ItemBody,
  Sender,
} from "../../documentSet/interfaces/documentSet";
import { NormalizationErrorType } from "../../annotation/interfaces/annotation";

export enum DrawerType {
  DETAILS = "details",
  ANNOTATION_HISTORY = "annotationHistory",
}

export interface DocumentSetDto {
  id: string;
  input: Input;
  name: string;
  modifiedDate: Dayjs;
  createdDate: Dayjs;
  approvedDate: Dayjs;
  email?: EmailDto;
}

export type EmailDto = {
  id: number;
  sender: Sender;
  subject: string;
  categories: Array<string>;
  receivedDateTime: Date;
  emailReply?: EmailReply;
  itemBody?: ItemBody;
  toRecipients?: Array<Sender>;
  ccRecipient?: Array<Sender>;
};

export interface DetailsContent {
  id: string;
  documentSetName: string;
  source: string;
  email?: EmailDetailsContent;
}

export interface EmailDetailsContent {
  subject: string;
  senderName?: string;
  senderAddress?: string;
  toAddresses?: string;
  ccAddresses?: string;
  receivedDate?: Date;
  content?: string;
  categories?: string;
}

export interface HistoryItem {
  rev: number;
  actionType: ActionType;
  value: string;
  createdDate: Date;
  itemTranslations: Translation;
  groupBlockTranslations: Translation;
  index: number;
  multipleGroupBlocks: boolean;
  type: HistoryItemType;
  errorType?: NormalizationErrorType;
}

export interface Document {
  rev: number;
  actionType: ActionType;
  value: string;
  createdDate: Date;
  itemTranslations: Translation;
  groupBlockTranslations: Translation;
  index: number;
  multipleGroupBlocks: boolean;
  type: HistoryItemType;
}

export enum ActionType {
  ADD = 0,
  MODIFIED = 1,
  DELETE = 2,
}

export enum HistoryItemType {
  ENTITY = "ENTITY",
  CATEGORIZATION = "CATEGORIZATION",
}

export interface ContentItems {
  detailsData: DocumentSetDto | undefined;
  annotationHistoryData: Array<HistoryItem> | undefined;
}
