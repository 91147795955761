import { minutesToMilliseconds } from "../../common/utilities/number";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  changeForceTokenReFetch,
  changeIsTokenRefreshing,
} from "../store/userSlice";

const INTERVAL = minutesToMilliseconds(10);
const AUTH0_ERRORS = [
  "login_required",
  "missing_refresh_token",
  "invalid_grant",
];

const useAccessTokenRefresh = (
  shouldRun: boolean,
  callbackRefresh: (accessToken: string) => Promise<void>,
  callbackLogout: () => void
) => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const forceTokenReFresh = useAppSelector(
    (state) => state.userReducer.forceTokenReFetch
  );

  const handleUpdateAccessToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      await callbackRefresh(accessToken);
    } catch (e: any) {
      if (AUTH0_ERRORS.includes(e.error)) {
        callbackLogout();
      }
    } finally {
      dispatch(changeForceTokenReFetch(false));
      dispatch(changeIsTokenRefreshing(false));
    }
  }, [dispatch, getAccessTokenSilently, callbackRefresh, callbackLogout]);

  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    if (!isAuthenticated) {
      return;
    }

    const interval = setInterval(handleUpdateAccessToken, INTERVAL);

    return () => clearInterval(interval);
  }, [isAuthenticated, handleUpdateAccessToken, shouldRun]);

  useEffect(() => {
    if (!shouldRun) {
      return;
    }

    if (forceTokenReFresh) {
      handleUpdateAccessToken();
    }
  }, [shouldRun, handleUpdateAccessToken, forceTokenReFresh]);
};

export default useAccessTokenRefresh;
