import { NormalizationErrorType } from "../../annotation/interfaces/annotation";

export enum NormalizationType {
  DATE = "date",
  NUMBER = "number",
  NUMBER_RAVAGO = "numberRavago",
  PRICE_RAVAGO = "priceRavago",
  WEEK = "week",
}

export interface NormalizedOutput {
  id?: string;
  normalizedValue: string;
  normalizedMethod: string | undefined;
  index?: number;
  options?: Array<string>;
  errorMessage?: string;
  errorType?: NormalizationErrorType;
}
